/* Campaign.css */

.campaignContainer {
    background-color: yellow; /* Yellow background color */
    height: 80vh; 
    /* display: flex; */
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }
  
  .campaignSection {
    text-align: center;
  }
  
  .campaignTitle {
    color: blue; /* Blue title color */
    font-size: 2rem;
    font-weight: 600;
  }
  
  .imageContainer {
    margin-top: 20px;
  }
  
  .campaignImage {
    margin-top: 50px;
    width: 500px; /* Adjust size as necessary */
    height: 400px; /* Adjust size as necessary */
    border-radius: 10px; /* Optional: Rounded corners for aesthetics */
    animation: fade 5s infinite; /* Smooth transition animation */
  }
  
  @keyframes fade {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  
  @media (max-width: 768px) {
    .campaignImage {
      width: 200px; /* Adjust size for smaller screens */
      height: 150px; /* Adjust size for smaller screens */
    }
    .campaignContainer{
        height: 50vh;
    }
  }
  