.text {
    font-size: 24px;
    font-weight: bold;
    color: purple; /* or any color you prefer */
    text-align: center;
    line-height: 100px;/* Adjust according to your design */
    border-bottom: 3px solid black; /* Adding a thin border */
    border-top: 3px solid black;
    background-color: lightblue;
    height: 10vh;
  
  }

    

/*

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  h2{
    color: white;
    text-align: center;
  }
  
  .mainContainer{
    margin: 10px;
  }
  
  .mainContainer .container{
    padding: 40px 0;
  }
  
  .mainContainer .container img{
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
  }
  
  div h3{
    color: white;
    font-weight: bold;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }*/