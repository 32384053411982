/* CSS code with corrections */

.feature-section {
  text-align: center;
  height: 80vh;
  background-color: magenta;
}

.section-title {
  font-size: 40px;
  color: blue;
  margin-bottom: 20px;
  padding-top: 20px;
  font-weight: 700;
}

.image-container {
  max-width: 100%;
  margin: 0 auto;
  height: 400px;
  position: relative;
  display: flex; /* Add flex display */
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  width: 50%;
  text-align: center;
  border-radius: 20px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
}

.image.active {
  opacity: 1;
}

.description {
  position: absolute;
  font-size: 24px;
  font-weight: 600;
  left: 0px;
  right: 0px;
  bottom: -35px;
  color: blue;
}

.centered-button {
  padding: 5px 5px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  margin: 40px auto;
  display: inline-block;
  background-color: yellow;
  border-radius: 20px;
}

.centered-button:hover {
  background-color: lightyellow;
}
.desc-text{
  position: absolute;
  font-size: 20px;
  left: 0px;
  right: 0px;
  bottom: -35px;
}


/* Media queries for responsiveness */



/* @media screen and (min-width: 768px) and (max-width: 1400px)and (max-height:1080px){
  .feature-section {
    height: 85vh;
  }
  
  .section-title {
    font-size: 30px;
  }
  
  .image-container {
    max-width: 50%;
    height: 43vh;
    margin-top: 50px;
  }
  .image-container p{
    top: 300px;
    font-size: 24px;
    font-weight: 600;
  }

  .centered-button {
    top: 130px;
    padding-bottom: 3px;
    padding-top: 3px;
    left: 650px;
  }

  .image {
    width: 90%;
    height: 50vh;
    margin-bottom: 50px;
    left: 350px;
    margin-top: 40px;
  }
  .description {
      
      color: white;
      font-weight: 400;
      padding: 10px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 15px;
    
  }
} */

@media screen and (max-width: 768px) /*and (max-height:1080px)*/{
  .section-title {
    font-size: 30px;
  }

  /* .feature-section {
    height: 70vh;
  } */

  .description {
    position: absolute;
    bottom: -35px;
    left: 0px;
    right: 0px;
  }

  .image {
    height: 40vh;
    margin-bottom: 50px;
    margin-right: 20px;
    margin-top: 90px;
    width: 90%;
    left: 20px;
  }
  

  .image-container {
    max-width: 100%;
    height: 43vh;
  }
  /* .image-container p{
    font-size: 20px;
    top: 350px
  }*/


  .centered-button{ 
    top: 80px;
    left: 200px;
    padding-top: 3px;
    padding-bottom: 3px;

  }


}

.btn-secondry{
  padding: 10px 10px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  margin: 40px auto;
  display: inline-block;
  background-color: yellow;
  border-radius: 20px;
}
.desc-text{
  position: absolute;
  font-size: 20px;
  left: 0px;
  right: 0px;
  bottom: -35px;
}

/* @media screen and (min-width: 2420px) and (max-width: 2500px) and (min-height: 700px) and (max-height: 800px){

  .feature-section {
    height: 90vh;
  }

  .image {
    height: 60vh;
    margin-bottom: 50px;
    margin-right: 10px;
    margin-top: 130px;
    width: 40%;
    left: 800px;
  }
  .image-container {
    max-width: 50%;
    height: 43vh;
    left: 100px;
  }
  .centered-button{
    padding-top: 5px;
    padding-bottom: 5px;
    left: 1150px;
    justify-content: center;
    
  }

} */
